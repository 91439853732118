<template>
  <nav id="navbarHeader" :class="btnFlag
      ? 'navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark align-items-center nav-sticky'
      : 'navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark align-items-center'
    ">
    <div class="container" :class="show ? 'show' : ''">
      <button class="navbar-toggler" @click="show = !show" type="button" data-toggle="collapse"
        data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
        <img v-show="!btnFlag" src="@/assets/images/daohanlan.svg" style="width: 1rem" alt="" />
        <img v-show="btnFlag" src="@/assets/images/daohanlans.svg" style="width: 1rem" alt="" />
      </button>

      <div class="collapse navbar-collapse" :class="show ? 'show' : ''" id="navbarCollapse">
        <ul class="navbar-nav navbar-center flex-wb" id="mySidenav">
          <li class="nav-item" :class="btnFlag ? 'nav-items' : ''" @click="routerBtn('/home')">
            <span class="T18px_Regular">主页</span>
          </li>
          <li class="nav-item" @click="routerBtn('/hire')" :class="btnFlag ? 'nav-items' : ''">
            <span class="T18px_Regular">出租</span>
          </li>
          <li class="nav-item" @click="routerBtn('/business')" :class="btnFlag ? 'nav-items' : ''">
            <span class="T18px_Regular">商业住宅</span>
          </li>
          <li class="nav-item" @click="routerBtn('/residence')" :class="btnFlag ? 'nav-items' : ''">
            <span class="T18px_Regular">居民住宅</span>
          </li>
          <li class="nav-item" @click="routerBtn('/renting_guide')" :class="btnFlag ? 'nav-items' : ''">
            <span class="T18px_Regular">租房指南</span>
          </li>
        </ul>
      </div>
      <!-- @click="routerBtn('/home','search_box')" -->
      <button @click="goAssignBlock('search', 30, '/home')"
        class="T20px_Regular w170px_btn animation_middle before_line">
        寻找房源
      </button>
    </div>
  </nav>
</template>

<script>
// import $ from 'jquery'
export default {
  name: "GlobalHeader",
  data() {
    return {
      btnFlag: false,

      show: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop, true);
  },
  methods: {
    /**
     * 滚动到指定位置
     */
    goAssignBlock(el, speed, url, query) {
      let path = this.$route.path;

      if (path.indexOf("url") == -1) {
        this.$router.push({ path: url, query: query });
      }

      setTimeout(() => {
        this.$emit("goAssignBlock", el, speed, url);
      }, 500);
    },
    scrollToTop() {
      this.show = false;
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 50) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
    routerBtn(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped lang="less">
@import "../assets/css/pc.css";

::v-deep .nav-item .dropdown-menu {
  background: rgba(41, 35, 35, 0.8);
}

::v-deep .nav-items .dropdown-menu {
  background: #fff;
}

.navbar-custom .navbar-nav {
  width: 100%;
  margin: 0 7.1875rem;

  .nav-item {
    color: #ffffff;
  }
}

.nav-sticky.navbar-custom .navbar-nav {
  width: 100%;
  margin: 0 7.1875rem;

  .nav-item {
    color: #000 !important;
  }
}

.before_line::before {
  content: "";
  position: absolute;
  width: 2.125rem;
  height: 0px;
  border: 0.0313rem solid #ffffff;
  bottom: -0.5625rem;
  right: 0;
  transition: all 1s;
}

.before_line:hover:before {
  transition: all 1s;
  background: #a6b565;
  border: 0.0313rem solid #a6b565;
}

.navbar-center,
.container {
  // display: flex;
  // align-items: center;
}

.navbar>.container.show {
  margin-top: 1.5rem;
  // display: flex;
  // align-items: center;
}

.show {
  flex-basis: auto;

  .navbar-nav {
    margin: 0 !important;
    flex-direction: column;
  }
}

.navbar-toggler {
  width: 5.3125rem;
}
</style>
