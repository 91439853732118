import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import { BasicLayout } from "@/layouts";

const routesP = [
  {
    path: "/",
    name: "Index",
    component: BasicLayout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("../pages/pc/index.vue"),
      },
      {
        path: "/houseInfo",
        name: "houseInfo",
        component: () => import("../pages/pc/houseInfo.vue"),
      },
      {
        path: "/hire",
        name: "hire",
        component: () => import("../pages/pc/hire.vue"),
      },
      {
        path: "/business",
        name: "business",
        component: () => import("../pages/pc/business.vue"),
      },
      {
        path: "/residence",
        name: "residence",
        component: () => import("../pages/pc/residence.vue"),
      },
      {
        path: "/renting_guide",
        name: "renting_guide",
        component: () => import("../pages/pc/renting_guide.vue"),
      },
    ],
  },
];

const routesM = [
  {
    path: "/",
    name: "Index",
    component: BasicLayout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("../pages/mobile/index.vue"),
      },
      {
        path: "/houseInfo",
        name: "houseInfo",
        component: () => import("../pages/mobile/houseInfo.vue"),
      },
      {
        path: "/hire",
        name: "hire",
        component: () => import("../pages/mobile/hire.vue"),
      },
      {
        path: "/business",
        name: "business",
        component: () => import("../pages/mobile/business.vue"),
      },
      {
        path: "/residence",
        name: "residence",
        component: () => import("../pages/mobile/residence.vue"),
      },
      {
        path: "/renting_guide",
        name: "renting_guide",
        component: () => import("../pages/mobile/renting_guide.vue"),
      },
    ],
  },
];

var routes = [];
if (
  navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
) {
  routes = routesM;
} else {
  routes = routesP;
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
