const baseSize = 16;
function setRem() {
  var bili = 960
  if(document.documentElement.clientWidth <= 768){
    bili = 375
  }
  const scale = document.documentElement.clientWidth / bili;
  document.documentElement.style.fontSize =
    baseSize * Math.min(scale, 2) + "px";
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem();
};
