import request from "./request.js";
import qs from "qs";

const baseUrl = "";

//获取房源
export function houseList(params) {
  return request({
    url: "/api/house",
    method: "get",
    params: params,
  });
}

//获取字典
export function dictInfo(params) {
  return request({
    url: "/api/house/dict",
    method: "get",
    params: params,
  });
}

//获取详情
export function details(params) {
  return request({
    url: "/api/house/details",
    method: "get",
    params: params,
  });
}

//退出
export function authLogout(params) {
  return request({
    url: baseUrl + "/logout",
    method: "get",
    params: params,
  });
}

//获取用户数据
export function getUserInfo(params) {
  return request({
    url: baseUrl + "/getUserInfo",
    method: "get",
    params: qs.stringfy(params),
  });
}

//提交邮箱
export function mailbox(data) {
  return request({
    url: baseUrl + "/api/mailbox/submit",
    method: "post",
    data: data,
  });
}


