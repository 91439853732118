<template>
  <div @scroll="getScrollTop">
    <GlobalHeader @goAssignBlock="goAssignBlock" />
    <section class="hero-5-bg position-relative">
      <router-view ref="view" :key="key" />
      <div class="scrollBox" v-show="isShow">
        <div class="scrollBox_box">
          <transition name="welcome1">
            <div v-show="yc" class="form_box flex-cc">
              <img src="../assets/images/form_text.png" class="form_text_image" alt="" />
              <div class="form_header_input flex-wb">
                <div class="form_header_input_item">
                  <input type="text" class="input_view  T14px_Regular" v-model="form.name"  placeholder="姓" />
                </div>
                <div class="form_header_input_item">
                  <input type="text" class="input_view T14px_Regular" v-model="form.names"  placeholder="名" />
                </div>
              </div>
              <div class="form_input_box">
                <input type="text" v-model="form.phone" class="input_view T14px_Regular" placeholder="联系方式" />
              </div>
              <div class="form_input_box">
                <input type="text"  v-model="form.email" class="input_view T14px_Regular" placeholder="电子邮箱" />
              </div>
              <div class="form_input_box">
                <textarea name="" class="textarea_view T14px_Regular" v-model="form.messages" placeholder="填写更多信息" id="" cols="30"
                  rows="10"></textarea>
              </div>

              <button @click="SubmitForm" class="form_btn T14px_Regular colorfff">立即发送</button>
            </div>
          </transition>
          <img src="../assets/images/pfu_user_logo.png" class="pfu_user_logo" @click="mouseover" alt="" />
          <!-- @mouseover="mouseover" -->
          <!-- @mouseleave="mouseleave" -->
          <img src="../assets/images/xtop.png" @click="upTop" class="xtop_logo" alt="" />

          <img src="../assets/images/weix_code.png" class="weix_code" alt="" />
        </div>
      </div>
    </section>
    <GlobalFooter @goAssignBlock="goAssignBlock" />
  </div>
</template>

<script>
import GlobalHeader from "@/components/GlobalHeader";
import GlobalFooter from "@/components/GlobalFooter";
import { mailbox } from "@/api/api.js";
export default {
  name: "BasicLayout",
  components: {
    GlobalHeader,
    GlobalFooter,
  },
  data() {
    return {
      scrollTop: 0,
      isShow: false,
      yc: false,
      form:{}
    };
  },
  computed: {
    key() {
      return this.$route.path + Date.now();
    },
  },
  created() { },
  mounted() {
    window.addEventListener("scroll", this.getScrollTop, true);
  },
  methods: {
    /***
     * 提交联系我们邮箱联系方式
     */
    SubmitForm(){
      let message = `名称：${this.form.name}/电话：${this.form.phone}/邮箱：${this.form.email}/想法：${this.form.messages}`;

      this.form.message = message;
      mailbox(this.form).then(()=>{
        
        this.form = {}
      })
    },
    // 监听滚动条
    getScrollTop() {
      let that = this; // 防止this指向问题
      // 设备一屏的高度
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;

      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
      // console.log(e, clientHeight);
      // 判断距离顶部多少显示回到顶部图标
      if (scrollTop > clientHeight) {
        that.isShow = true;
      } else {
        that.isShow = false;
      }
    },
    mouseover() {
      // 鼠标移入事件mouseover
      this.yc = !this.yc;

    },
    mouseleave() {
      // 鼠标移出事件mouseleave
      this.yc = false;
    },
    upTop() {
      document.documentElement.scrollTop = 0;
    },
    goAssignBlock(el, speed) {
      let windowH = window.innerHeight; //浏览器窗口高度
      console.log(this.$refs.view.$refs)
      let t = this.$refs.view.$refs[el].offsetTop;
      
      let top = t - (windowH - t) + windowH; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置

      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //滚动条距离顶部高度
      let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
      let requestId;
      //采用requestAnimationFrame，平滑动画
      function step() {
        //判断让滚动条向上滚还是向下滚
        if (scrollTop < top) {
          if (currentTop <= top) {
            //   window.scrollTo(x,y) y为上下滚动位置
            window.scrollTo(0, currentTop);
            requestId = window.requestAnimationFrame(step);
          } else {
            window.cancelAnimationFrame(requestId);
          }
          //向下滚动
          currentTop += speed;
        } else {
          if (top <= currentTop) {
            //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
            window.scrollTo(0, currentTop - speed);
            requestId = window.requestAnimationFrame(step);
          } else {
            window.cancelAnimationFrame(requestId);
          }
          //向上滚动
          currentTop -= speed;
        }
      }
      window.requestAnimationFrame(step);
    },
  },
};
</script>

<style lang="less" scoped>
/* 返回顶部样式 */
.scrollBox {
  position: fixed;
  right: 1.625rem;
  bottom: 6.75rem;
  font-size: 0.6875rem;
  z-index: 99;

  .scrollBox_box {
    display: flex;
    flex-direction: column;
    position: relative;

    .code_logo {
      position: absolute;
      width: 6.6563rem;
      left: -7.1875rem;
      top: -3.75rem;
      background: #fff;
      border-radius: 0.375rem;
    }

    .xtop_logo {
      width: 2.0625rem;

    }

    .pfu_user_logo {
      width: 2.0625rem;
      margin-bottom: 0.8125rem;
    }

    .weix_code {
      width: 2.1875rem;
      margin-top: 0.6875rem;
      border: .0625rem solid #A8B565;
      border-radius: .125rem;
    }
  }
}

.welcome1-enter-active,
.welcome1-leave-active {
  transition: opacity 0.5s;
}

.welcome1-enter,
.welcome1-leave-to {
  opacity: 0;
}

.form_box {
  position: absolute;
  left: -12.1875rem;
  top: -9.75rem;
  width: 11.5rem;
  height: calc(19.125rem - (0.625rem + 1.0313rem));
  padding-top: 0.625rem;
  padding-bottom: 1.0313rem;
  background-image: url("../assets/images/form_bj.png");
  background-size: 100% 100%;
  border-radius: 0.25rem;

  .form_text_image {
    width: 9.2813rem;
    height: 3.125rem;
  }

  .form_header_input {
    width: 8.4375rem;

    .form_header_input_item {
      .input_view {
        width: 3.9375rem;
        height: 1.3125rem;
        background: #d4d9be;
        border-radius: 0.125rem;
        border: none;
        margin-bottom: 0.7188rem;
      }
    }
  }

  .form_input_box {
    .input_view {
      width: 8.4375rem;
      height: 1.3125rem;
      background: #d4d9be;
      border-radius: 0.125rem;
      border: none;
      margin-bottom: 0.7188rem;
    }

    .textarea_view {
      width: 8.4375rem;
      height: 3.4375rem;
      background: #d4d9be;
      border-radius: 0.125rem;
      border: none;
      margin-bottom: 0.7188rem;
    }
  }

  input::input-placeholder,
  .textarea {
    color: #8c9859;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #8c9859;
    text-align: center;
  }

  input::-moz-placeholder,
  textarea:-moz-placeholder {
    color: #8c9859;
    text-align: center;
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #8c9859;
    text-align: center;
  }

  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    color: #8c9859;
    text-align: center;
  }

  textarea::-ms-input-placeholder,
  textarea::-moz-placeholder,
  textarea:-moz-placeholder,
  textarea::-webkit-input-placeholder {
    line-height: 3.4375rem;
  }

  .form_btn {
    width: 8.4375rem;
    height: 1.3125rem;
    border-radius: 0px 0px 0px 0px;
    border: 0.0313rem solid #ffffff;
    background: transparent;
    margin-top: 0.4375rem;
  }
}
</style>
