import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
 
Vue.use(VueAwesomeSwiper, /* { 全局组件的默认选项 } */)

//动画
// 使用样式库
import animated from 'animate.css';
Vue.use(animated);
 
// 滚动动画 wow.js
import {
  WOW
} from 'wowjs'

Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default
  animateClass: 'animated', // default
  offset: 50, // default
  mobile: true, // default
  live: false,
 
  // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.
 

});
// import "amfe-flexible/index";

import "./utils/rem";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);


// import 'jquery-ui-dist/jquery-ui'
// import 'jquery-ui-dist/jquery-ui.min.css'


// 引入css样式
import './plugins/bootstrapvue'                  // 引入 bootstrap-vue
import 'zico/css/zico.min.css'                   // zico 图标库 css
import 'github-markdown-css/github-markdown.css' // markdown css
import './assets/css/animate.css'
import './assets/css/style.css'
import './assets/css/font.css'

// 挂载 axios
Vue.prototype.$http = axios

Vue.config.productionTip = false
// 配置 Ajax 请求根地址
axios.defaults.baseURL = 'http://127.0.0.1:8000/api'

// 代码高亮
import hljs from 'highlight.js'
// import 'highlight.js/styles/github.css'
Vue.directive('highlight', function (el) {
  let blocks = el.querySelectorAll('pre code');
  blocks.forEach((block) => {
    hljs.highlightBlock(block)
  })
})

import App from './App.vue'
import axios from 'axios'

import router from './router'
import store from './store'





new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
